import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Auth_API from '../../../api/authService/authservice';

interface LogoState {
  logo: string;
}

const initialState: LogoState = {
  logo: '',
};

// Create an async thunk to fetch the logo
export const getLogo = createAsyncThunk(
  'logo/getLogo',
  async () => {
    try {
      const response = await Auth_API.logo();
      return response.data.data;  
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const logoSlice = createSlice({
  name: 'logo',
  initialState,
  reducers: {
    setLogo: (state, { payload }) => {
      state.logo = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLogo.fulfilled, (state, { payload }) => {
      state.logo = payload;
    });
  },
});

export const { setLogo } = logoSlice.actions;

export default logoSlice.reducer;
