import React from "react";

const ErrorPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-6xl font-bold text-gray-800">404</h1>
      <h2 className="mt-4 text-2xl text-gray-600">Page Not Found</h2>
      <p className="mt-2 text-lg text-gray-500">
        Sorry, the page you are looking for does not exist or has been moved.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        If you need assistance, please contact the admin.
      </p>
    </div>
  );
};

export default ErrorPage;
