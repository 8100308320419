import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import logoActive from "../asset/No-code Logo.svg";
import inActive from "../asset/openIcon .svg";
import Active from "../asset/leftDoubleArrow.svg";
import Main from "./side/sideComponent/sideMain";
import MainInactive from "./side/sideComponent/mainInactive";
import { setUserData } from "../redux/slices/employes/userDataSlice";
import { useAppDispatch, useAppSelector } from "../redux/hook/authHook";
import { getLogo } from "../redux/slices/auth/logo";

function Navbar() {
  const [active, setActive] = useState(false);

  const showMore = () => {
    setActive(true);
  };

  const showLess = () => {
    setActive(false);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getLogo());
        dispatch(setUserData(response.payload));
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, [dispatch]);

  const logo = useAppSelector((state) => state.logo.logo);
  return (
    <div className={`navbar ${active ? "active" : ""} w-[80px]`}>
      <div
        className={`
        "animate duration-300 border-r border-gray-700 relative flex flex-col pt-5 max-h-screen group " 
        }`}
      >
        <Link to="/user/dashboard" className="px-2 pb-7">
          {active ? (
            logo ? (
              <div className="pl-5">
                {/* <img
                  src={logo}
                  alt='Logo'
                  className='  object-contain h-[88px] rounded-full w-40 '
                /> */}
              </div>
            ) : (
              <ReactSVG src={logoActive} />
            )
          ) : (
            <ReactSVG
              src={inActive}
              onClick={showMore}
              className="border border-[#475467] rounded-[4px] p-4"
            />
          )}
        </Link>
        {active && (
          <ReactSVG
            src={Active}
            onClick={showLess}
            className="absolute text-2xl p-4 text-white border border-[#475467] rounded-[4px] cursor-pointer right-5 top-4  "
          />
        )}
        {active ? (
          <div>
            <Main />
          </div>
        ) : (
          <div>
            <MainInactive active={showMore} />
          </div>
        )}
      </div>
    </div>
  );
}
export default Navbar;
