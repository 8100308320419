import React from "react";
import { ReactSVG } from "react-svg";
import hols from "../../asset/Icon (1).svg";
import PublicHolidays from "../../utilities/components/publicHolidays";
import { Holiday } from "../../utilities/types/types";

interface HolidaysProps {
  holiday: Holiday[];
}

function Holidaay({ holiday }: HolidaysProps) {
  return (
    <div>
      <div className="flex items-center p-4 border rounded-t-lg">
        <ReactSVG src={hols} className="mr-2" />
        <p className="text-xl font-medium text-[#158B8D]">
          Upcoming Public Holidays
        </p>
      </div>
      <div className="px-4 border rounded-b-lg">
        <PublicHolidays holidays={holiday} />
      </div>
    </div>
  );
}

export default Holidaay;
