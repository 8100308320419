import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Leave_API from "../../../api/leaveService";
import { Holiday } from "../../../utilities/types/types";



// Define the type for your slice state
interface HolidayState {
  holidays: Holiday[];
}

// Create an async thunk to fetch employee data
export const getHolidays = createAsyncThunk("holidays", async () => {
  try {
    const response = await Leave_API.holiday();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const holidaysSlice = createSlice({
  name: "holidays",
  initialState: {
    holidays: [],
  } as HolidayState,
  reducers: {
    setHolidays: (state, { payload }) => {
      state.holidays = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(holidays.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setHolidays } = holidaysSlice.actions;

export default holidaysSlice.reducer;

