import  getAllLeave  from './slices/leave/allLeave';
import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/authSlice';
import userDataSlice, { getEmployee } from './slices/employes/userDataSlice';
import birthday from './slices/employes/birthday';
import workAniversary from './slices/employes/workAniversary';
import allExpenses from './slices/expenses/allExpenses';
import approveExpense from './slices/expenses/approveExpense';
import reimburseExpense from './slices/expenses/reimburseExpense';
import requestExpense from './slices/expenses/requestExpense';
import editExpenses from './slices/expenses/editExpenses';
import getOneExpense from './slices/expenses/getOneExpense';
import  getCategory   from './slices/expenses/customCategories';
import getAllEmployee  from './slices/employes/employee';
import imageSlice from './slices/auth/imageSlice';
import payslipSlice from './slices/payslipSlice';
import fileUpload from './slices/auth/fileUpload';
import getFiles from './slices/getFiles';
import activity from './slices/expenses/activity';
import getPayslipData from './slices/paySlipData';
import emergencyContact from './slices/employes/emergencyContact';
import  getNewMembers  from './slices/employes/newMebers';
import getLogo from './slices/auth/logo';
import getLeaveCategory  from './slices/leave/categories';
import getAnalytics  from './slices/leave/analytics';
import getOneLeave from './slices/leave/getOneLeave';
import getLeaveActivities  from './slices/leave/activity';
import  getHolidays  from './slices/leave/holiday';
import orgSlice from './slices/auth/orgSlice';




export const store = configureStore({
  reducer: {
    org:orgSlice,
    auth: authSlice,
    user: userDataSlice,
    birthdays: birthday,
    work: workAniversary,
    allExpenses: allExpenses,
    approvedExpenses: approveExpense,
    reimbursedExpenses: reimburseExpense,
    requestedExpenses: requestExpense,
    editExpensesForm: editExpenses,
    oneExpense: getOneExpense,
    category:getCategory,
    active: getAllEmployee,
    image: imageSlice,
    payslip: payslipSlice,
    upload: fileUpload,
    file: getFiles,
    activity:activity,
    employee:getAllEmployee,
    payslipData:getPayslipData,
emergencyContact:emergencyContact,
newMembers:getNewMembers,
logo:getLogo,
leave:getAllLeave,
leaveCategory: getLeaveCategory,
analytics:getAnalytics,
oneLeave:getOneLeave,
leaveActivity:getLeaveActivities,
holiday:getHolidays,
    // file: fileUpload,
    
    // onboardingForm: onboardingFormSlice,
    // employee: employeeReducer,
    // analytics: analyticsSlice,
   
    // archived: archiveEmployees,
    // admin: adminDetails,
    // bulkUpload: bulkUpload,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
