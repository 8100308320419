import React from "react";
import { Holiday } from "../types/types"; // Adjust the import path as needed

interface PublicHolidaysProps {
  holidays: Holiday[];
}

function PublicHolidays({ holidays }: PublicHolidaysProps) {
  console.log(holidays);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const today = new Date();

  const upcomingHolidays = holidays
    .filter((holiday) => new Date(holiday.date) > today)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .slice(0, 3);

  return (
    <div>
      {upcomingHolidays.length > 0 ? (
        upcomingHolidays.map((holiday, index) => (
          <div
            key={index}
            className={`flex items-center p-4 ${index !== 0 ? "border-t" : ""}`}
          >
            <div className="mr-4">
              <div className="relative w-10 h-10 overflow-hidden rounded-full border-2 border-gray-300">
                {holiday.flag && (
                  <img
                    src={holiday.flag}
                    alt={`${holiday.name} image`}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                )}
              </div>
            </div>

            <div>
              <p className="text-sm font-medium">{formatDate(holiday.date)}</p>
              <p className=" line-clamp-2 text-sm text-gray-700">
                {holiday.name} - {holiday.description}
              </p>
            </div>
          </div>
        ))
      ) : (
        <p>No upcoming holidays found.</p>
      )}
    </div>
  );
}

export default PublicHolidays;
