import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Auth_API from '../../../api/authService/authservice';

interface OrganizationState {
  organization: any;
  loading: boolean;
  error: string | null;
}

export const fetchOrg = createAsyncThunk(
  'organization/fetchOrg',
  async (name: string, thunkAPI) => {
    try {
      const res = await Auth_API.org(name);
      return res?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    organization: null,
    loading: false,
    error: null,
  } as OrganizationState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrg.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload;
      })
      .addCase(fetchOrg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default organizationSlice.reducer;
