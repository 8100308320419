import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../redux/store"; // Import AppDispatch
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import { fetchOrg } from "../redux/slices/auth/orgSlice";
import ErrorPage from "../utilities/components/notFound";

const OrgPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate(); // Initialize navigate for programmatic routing

  const { organization, loading, error } = useSelector(
    (state: RootState) => state.org
  );

  // Local loading state
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    const orgName = hostname.split(".")[0];
    if (hostname === "quiickops.com") {
      window.location.href = "https://quiickopshr.com";
      return;
    }
    if (orgName) {
      setLocalLoading(true);
      dispatch(fetchOrg(orgName)).then(() => {
        setLocalLoading(false);
      });
    }

    if (organization) {
      navigate("/login");
    }
  }, [dispatch, organization, navigate]);

  return (
    <div>
      {(localLoading || loading) && (
        <p className="text-3xl p-24">Loading ........</p>
      )}
      {error && <ErrorPage />}
    </div>
  );
};

export default OrgPage;
