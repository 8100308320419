import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Employee, SignInForm, email } from '../../../utilities/types/types';
import AuthModule from '../../../api/authService/Auth.module';
import Auth_API from '../../../api/authService/authservice';


export const login = createAsyncThunk(
  'login',
  async (data: SignInForm, thunkAPI) => {
    try {
      const res = await Auth_API.login(data);
      AuthModule.authenticateUser(res);
      thunkAPI.dispatch(
        setUser({ user: res?.data?.data, isAuthenticated: true, error: '' })
      );

      return res;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const forgetPassword = createAsyncThunk(
  'forgotPassword',
  async (data: email, thunkAPI) => {
    try {
      const res = await Auth_API.forgotPassword(data);

      return res;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.res?.data || error.message);
    }
  }
);
export const logout = createAsyncThunk('auth/logout', async () => {
  AuthModule.deAuthenticateUser();
});

// Slice
const user: Employee | null = JSON.parse(localStorage.getItem('user')!);
const token = localStorage.getItem('token');

export interface AuthState {
  error: string;
  loading: boolean;
  isAuthenticated: boolean | null;
  user: Employee | null;
}
const initialState = {
  error: '',
  loading: false,
  isAuthenticated: !!user && !!token,
  user: user || (null as Employee | null),
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (
      state,
      { payload }: { payload: Partial<typeof initialState> }
    ) => {
      state.user = payload.user ?? state.user;
      state.isAuthenticated = payload.isAuthenticated ?? state.isAuthenticated;
      state.loading = payload.loading ?? state.loading;
      state.error = payload.error ?? state.error;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(login.pending, (state: AuthState) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state: AuthState, action) => {
        state.loading = false;
        state.isAuthenticated = true;
      })
      .addCase(
        login.rejected,

        (state: AuthState, { payload }: any) => {
          state.loading = false;
          state.error = payload;
          state.isAuthenticated = false;
          state.user = null;
        }
      )
      .addCase(forgetPassword.pending, (state: AuthState) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(forgetPassword.fulfilled, (state: AuthState, action) => {
        state.loading = false;
      })
      .addCase(
        forgetPassword.rejected,
        (state: AuthState, { payload }: any) => {
          state.loading = false;
          state.error = payload;
        }
      )
      .addCase(logout.fulfilled, (state: AuthState, action) => {
        return state;
      });
  },
});

// export const { setUser } = authSlice.actions;
export const { setUser } = authSlice.actions;

export default authSlice.reducer;

// Correct the RootState type
export type RootState = ReturnType<typeof authSlice.reducer>;
